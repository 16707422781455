import { call, put, takeLatest, delay } from 'redux-saga/effects'
import CONST from '../helpers/constant';
import {
	successPatientData,
	errorPatientData,
	successCountryData,
	errorCountryData,
	successPatientCreate,
	errorPatientCreate
} from '../actions'

import {
	getPatientListAPI,
	getCountryListAPI,
	createPatientAsyncAPI
} from '../api'


function* getPatientListAsync({ payload }) {
	
	try {
		const data = yield call(getPatientListAPI, payload);
		if (data.status === 200) {
			yield put(successPatientData(data.data));
		} else {
			yield put(errorPatientData({
				message: "Error while retrieving data"
			}));
		}
	} catch (e) {
		console.log(e)
		yield put(errorPatientData({
			message: "Error while retrieving data"
		}));
	}
}
export function* getProductList(action) {
	yield takeLatest(CONST.PATIENT.REQUEST_DATA, getPatientListAsync);
}


function* getCountryListAsync({ payload }) {
	try {
		const data = yield call(getCountryListAPI, payload);
		if (data.status === 200) {
			yield put(successCountryData(data.data));
		} else {
			yield put(errorCountryData({
				message: "Error while retrieving data"
			}));
		}
	} catch (e) {
		yield put(errorCountryData({
			message: "Error while retrieving data"
		}));
	}
}
export function* getCountryList(action) {
	yield takeLatest(CONST.COUNTRY.REQUEST_DATA, getCountryListAsync);
}


function* createPatientAsync({ payload }) {
	console.log('createpatiennt')
	console.log(payload)
	try {
		const data = yield call(createPatientAsyncAPI, payload);
		
		if (data.status === 200) {
			yield put(successPatientCreate({
				message: "Error while retrieving data"
			}));
		} else {
		
			yield put(errorPatientCreate({
				message: "Error while retrieving data"
			}));
		}
	} catch (e) {
		console.log('catcj-createpatiennt')
			console.log()
		yield put(errorPatientCreate({
			message: "Error while retrieving data"
		}));
	}
}
export function* createPatient(action) {
	yield takeLatest(CONST.PATIENT.REQUEST_CREATE, createPatientAsync);
}

