import React, { Component } from "react";
import { Redirect } from 'react-router';
import { Button, Checkbox, Form, Input, Alert } from "antd";

import CONST from '../../../helpers/constant';
import Background from '../../../assets/img/mainimg.jpeg';

import siteLogo from '../../../assets/img/logo.png';

const FormItem = Form.Item;

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 24 },
};
const tailLayout = {
	wrapperCol: { span: 24 },
};


class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			notice: '',
			autoLogin: true,
			redirect: false,
			// countryFlag: ['eu', 'france', 'germany', 'Italy', 'spain', 'switzerland', 'uk', 'canada'],
			countryFlag: ['eu', 'france', 'germany', 'switzerland', 'uk', 'canada'],
			renderCountryFlag: "",
			activeCountry: "eu"
		};
	}


	componentDidMount() {
		if (localStorage.getItem('user_id') !== null) {
			// this.props.history.push('/patient');
			this.setState({ redirect: true });
		}
		this.renderCountryFlag();
	}
	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			console.log("values", values)
		});
	};

	onFinish = values => {

		if (values.username.trim() === "CalcUser1" && values.password.trim() === "VaxCalc1!") {
			localStorage.setItem('user_id', values.username);
			localStorage.setItem('selected_country', this.state.activeCountry);
			window.location.replace("/dashboard");
		} else {
			this.setState({
				notice: 'The username or password is incorrect!',
			});
		}

	};

	onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
	};

	onCountryClick = (countryName) => {

		this.setState({ activeCountry: '' }, () => {
			this.setState({ activeCountry: countryName }, () => {
				this.renderCountryFlag();
			});
		});
	}

	getCuntryName = (cName) => {

		if (cName === "eu") {
			return "European Union";
		} else if (cName === "france") {
			return "France";
		} else if (cName === "germany") {
			return "Germany";
		} else if (cName === "switzerland") {
			return "Switzerland";
		} else if (cName === "uk") {
			return "United Kingdom";
		} else if (cName === "canada") {
			return "Canada";
		}

	}

	renderCountryFlag = () => {
		let ans = this.state.countryFlag.map((t) => {

			return (<>

				<div className={`${this.state.activeCountry === t ? 'active' : ''} country-img`} onClick={() => this.onCountryClick(t)}>
					<img src={`${require('../../../assets/img/' + t + '.png')}`}></img>
					{this.state.activeCountry === t ? <div className="country-name-display">
						{this.getCuntryName(t)}
					</div>
						: <></>}
				</div>


			</>
			)
		});

		this.setState({ renderCountryFlag: ans });
	}
	render() {
		if (this.state.redirect) {
			return <Redirect push to="/dashboard" />;
		}


		return (
			<>
				<div className="limiter">
					<div className="container-login100">
						<div className="wrap-login100">
							<div className="login100-more" style={{ backgroundImage: `url(${Background})` }}>
							</div>

							<div className="login100-form validate-form">
								<div className="country-div">
									<img className="login-logo" src={siteLogo}></img>
								</div>

								<div className="site-title-login-form">
									<h4>Vaccine Cost Calculator</h4>
								</div>

								<div className="country-div">
									<h3>Choose the desired region</h3>
									{this.state.renderCountryFlag}
								</div>

								<Form
									{...layout}
									className=""
									name="loginForm"
									onFinish={this.onFinish}
									onFinishFailed={this.onFinishFailed}
								>

									<Form.Item
										name="username"
										rules={[{ required: true, message: 'Please enter username' }]}
									>
										<Input placeholder="Username" style={{ width: '300px' }} />
									</Form.Item>

									<Form.Item
										name="password"
										rules={[{ required: true, message: 'Please enter password!' }]}
									>
										<Input.Password placeholder="Password" style={{ width: '300px' }} />
									</Form.Item>
									<Form.Item {...tailLayout}>
										<Button type="primary" htmlType="submit">
											Login
      					 				   </Button>
									</Form.Item>
								</Form>
							</div>

						</div>
					</div>
				</div>
			</>
		);
	}
}



export default Login;
