var match = document.cookie.match(new RegExp('(^| )session_id=([^;]+)'));

export default {

  API: {
    // URL: 'http://localhost:3888'
    // URL: 'http://xyz.xyz:58069',
    URL: 'https://xyz.xyz',
    SESSIONID: match ? match[2] : "0626031a8ca3c57c6b3b180eb0c0bdaeca4695d0"
    // SESSIONID: document.cookie.match(new RegExp('(^| )session_id=([^;]+)'))?
  },
  PATIENT: {
    REQUEST_DATA: 'PATIENT_LIST_REQUEST_DATA',
    SUCCESS_DATA: 'PATIENT_LIST_SUCCESS_DATA',
    FAILED_DATA: 'PATIENT_LIST_FAILED_DATA',

    REQUEST_CREATE: 'PATIENT_LIST_REQUEST_CREATE',
    SUCCESS_CREATE: 'PATIENT_LIST_SUCCESS_CREATE',
    FAILED_CREATE: 'PATIENT_LIST_FAILED_CREATE',



  },

  COUNTRY: {
    REQUEST_DATA: 'COUNTRY_LIST_REQUEST_DATA',
    SUCCESS_DATA: 'COUNTRY_LIST_SUCCESS_DATA',
    FAILED_DATA: 'COUNTRY_LIST_FAILED_DATA',
  },

};