import CONST from '../helpers/constant';

const defaultState = {
	isLoading: false,
	isDeleting: 0,
	errorMessage: '',
	successMessage: '',
	successPatientCreate: '',
	current: {},
	list: [],
	errors: [],
	patientCreateFlag: 1,

	countryList: [],
	isCountryLoading: false
};

export default (state = defaultState, { type, payload }) => {
	state.errorMessage = '';
	state.successMessage = '';

	switch (type) {
		case CONST.PATIENT.REQUEST_DATA:
			return {
				...state,
				isLoading: true
			};
		case CONST.PATIENT.FAILED_DATA:
			return {
				...state,
				isLoading: false,

				errorMessage: payload.message
			};
		case CONST.PATIENT.SUCCESS_DATA:
			return {
				...state,
				isLoading: false,

				list: payload
			};

		case CONST.PATIENT.REQUEST_CREATE:
			return {
				...state,
				patientCreateFlag: 1,
				isLoading: true
			};
		case CONST.PATIENT.SUCCESS_CREATE:
			return {
				...state,
				patientCreateFlag: 2,
				successPatientCreate: payload.message,
				isLoading: false,
			};
		case CONST.PATIENT.FAILED_CREATE:
			return {
				...state,
				isLoading: false,
				patientCreateFlag: 3,
				errorMessage: payload.message
			};


		// Start for the Country Reducer		
		case CONST.COUNTRY.REQUEST_DATA:
			return {
				...state,
				isCountryLoading: true
			};
		case CONST.COUNTRY.SUCCESS_DATA:
			return {
				...state,
				isCountryLoading: false,
				countryList: payload
			};

		default:
			return state;
	}

}