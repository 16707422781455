import CONST from '../helpers/constant';


//PATIENT
export const requestPatientData = (data = {}) => ({ type: CONST.PATIENT.REQUEST_DATA, payload: data });
export const successPatientData = data => ({ type: CONST.PATIENT.SUCCESS_DATA, payload: data });
export const errorPatientData = data => ({ type: CONST.PATIENT.FAILED_DATA, payload: data });

//PATIENT
export const requestPatientCreate = (data = {}) => ({ type: CONST.PATIENT.REQUEST_CREATE, payload: data });
export const successPatientCreate = data => ({ type: CONST.PATIENT.SUCCESS_CREATE, payload: data });
export const errorPatientCreate= data => ({ type: CONST.PATIENT.FAILED_CREATE, payload: data });


//Country
export const requestCountryData = (data = {}) => ({ type: CONST.COUNTRY.REQUEST_DATA, payload: data });
export const successCountryData = data => ({ type: CONST.COUNTRY.SUCCESS_DATA, payload: data });
export const errorCountryData = data => ({ type: CONST.COUNTRY.FAILED_DATA, payload: data });
