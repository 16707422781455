import React, { Component } from 'react';
// import NavBar from './NavBar';
// import Home from '../testComponents/Home';
// import Footer from './Footer';
import { Redirect } from 'react-router';

import AppRouters from '../AppRouters';
import { Layout, Menu, Breadcrumb, Popover, Avatar, Row, Col } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import SideBar from './SideBar';
import moment from 'moment';
import siteLogo from '../../assets/img/logo.png';

const { Header, Content, Footer, Sider } = Layout;

const { SubMenu } = Menu;


class ContentWrapper extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    }
  }
  userSignOut = () => {
    console.log(this.props);
    localStorage.removeItem('user_id');
    localStorage.removeItem('selected_country');
    this.setState({ redirect: true });
    // this.props.history.replace('/login');
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }

    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li>My Account</li>
        <li onClick={this.userSignOut}>Logout
        </li>
      </ul>
    );
    return (
      <Layout style={{ minHeight: '100vh' }}>
        {/* <SideBar /> */}

        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }} >
            <Row>
              <Col span={8}>
                <div className="site-logo">
                  <img src={siteLogo}></img>
                  </div></Col>
              <Col span={8}>
                <div style={{ textAlign: "center" }}>
                  {moment().format(" YYYY-MM-DD h:mm:ss a")}
                </div>
              </Col>
              <Col span={8}>
                <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
                  <div onClick={this.userSignOut}><LogoutOutlined /> Logout</div>
                </div>
              </Col>
            </Row>
            {/* <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
              <div onClick={this.userSignOut}><LogoutOutlined /> Logout</div>
            </div> */}
          </Header>
          <Content style={{ margin: '0 16px' }}>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <AppRouters />
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}></Footer>
        </Layout>
      </Layout>
    )
  }
}

export default ContentWrapper;

