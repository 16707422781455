

import axios from 'axios';
import CONST from '../helpers/constant';


import FormData, { getHeaders } from 'form-data';
const URL = CONST.API.URL;

export const getPatientListAPI = (payload = {}) => {

  // var data = JSON.stringify({ "jsonrpc": "2.0", "method": "call", "params": { "model": "res.partner", "fields": ["name", "email", "ref", "mobile", "phone", "street", "street2", "city", "state_id", "zip", "country_id", "is_patient", "is_person"], "domain": [["is_patient", "=", "True"]], "offset": 0, "limit": 0, "sort": "" } });

  // var config = {
  //   method: 'post',
  //   url: 'http://lemonglitter.xyz:58069/web/dataset/search_read',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   data: data
  // };

  // axios(config)
  //   .then(function (response) {
  //     console.log(JSON.stringify(response.data));
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });

  var data = new FormData();
  data.append('method', 'POST');
  data.append('payload', JSON.stringify(payload));
  data.append('url', `${URL}/web/dataset/search_read`);
  data.append('session', 'f1311483bc71638365a5f4d3cf609ba4440a28af');

  var config = {
    method: 'POST',
    url: `${URL}/api/fetch-customer-data`,
    data: data
  };


  try {
    return axios(config).then(res => {
      console.log(res.data.result.result);
      return {
        data: res.data.result.result.records,
        status: 200
      };
    }).catch(err => {
      return {
        data: err,
        status: 422
      };
    });
  } catch (error) {
    return error;
  }

  // console.log('call--------')
  // let data = {
  //   method: "POST",
  //   payload: '{"jsonrpc": "2.0","method": "call","params": {"model": "res.partner","fields": ["name", "email", "ref", "mobile", "phone", "street", "street2", "city", "state_id", "zip", "country_id", "is_patient", "is_person"],"domain": [["is_patient", "=", "True"]],"offset": 0,"limit": 0,"sort": ""}}',
  //   url: `${URL}/web/dataset/search_read`
  // }
  // try {
  //   return axios.post(`${URL}/api/fetch-customer-data`, data,
  //     {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     }
  //   ).then(data => {
  //     return {
  //       data: data.data.result,
  //       status: 200
  //     };
  //   }).catch(err => {
  //     return {
  //       data: err,
  //       status: 422
  //     };
  //   });
  // } catch (error) {
  //   return error;
  // }


  // try {
  //   return axios.post(`${URL}/web/dataset/search_read`, payload, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   }).then(data => {
  //     return {
  //       data: data.data.result,
  //       status: 200
  //     };
  //   }).catch(err => {
  //     return {
  //       data: err,
  //       status: 422
  //     };
  //   });
  // } catch (error) {
  //   return error;
  // }

}

export const getCountryListAPI = (payload = {}) => {
  console.log('get the patient list')
  try {
    return axios.get(`${URL}/countryList`, {
      params: payload
    }).then(data => {
      return {
        data: data.data.result.records,
        status: 200
      };
    }).catch(err => {
      return {
        data: err,
        status: 422
      };
    });
  } catch (error) {
    return error;
  }

}


export const createPatientAsyncAPI = (payload = {}) => {

  var config = {
    method: 'post',
    url: `${URL}/web/dataset/call_kw`,
    headers: { 'Content-Type': 'application/json' },
    data: payload
  };

  axios(config).then((data) => {
    return {
      data: data.data.result.records,
      status: 200
    };
  }).catch((error) => {
    return {
      data: error,
      status: 422
    };

  });

  return {
    data: "",
    status: 200
  };

}

