import React, { Component, useEffect, useState } from 'react';
import { Link, useLocation, matchPath, NavLink } from 'react-router-dom';
import { Layout, Menu, Breadcrumb } from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;


export default (props) => {

  let [collapsed, setCollapsed] = useState(false);
  //   const location = useLocation();

  //   useEffect(() => {
  //     // getActiveClass
  //   });

  //   const getActiveClass = (menuName) => {

  //     const productsRouter = {
  //       PRODUCT_MENU: [
  //         '/product/',
  //         '/product/create',
  //         '/product/:id/edit',
  //       ],
  //       USER_MENU: [
  //         '/productusers'
  //       ]
  //     };

  //     const currentRoutes = productsRouter[menuName];
  //     const matched = matchPath(location.pathname, currentRoutes);
  //     if (matched) {
  //       return 'active';
  //     }

  //   }

  const onCollapse = () => {
    setCollapsed(!collapsed)
  }

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
      <div className="logo">
          <div className="logo-content">LG</div>
      </div>
      <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
        <Menu.Item key="2" icon={<UserOutlined />}>

          <NavLink exact to="/patient">
            Patient
          </NavLink>

        </Menu.Item>
      </Menu>
    </Sider>
  )
}