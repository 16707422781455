import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';
import { Layout, Menu, Breadcrumb } from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import './App.css';
import './main.css';
import './util.css';
import { Provider } from 'react-redux';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { history } from './helpers';
import store from './helpers/store';
import SideBar from './components/layouts/SideBar';
import ContentWrapper from './components/layouts/ContentWrapper';
import PageTop from './components/layouts/PageTop';
import Login from './components/views/users/login';



const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class App extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  render() {

    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route exact path="/login" component={Login} />
            {localStorage.getItem('user_id') === null ? <Redirect to="/login" /> :
              <>
                <ContentWrapper />
                <PageTop />
              </>
            }
          </Switch>
        </Router>
      </Provider>


    );
  }
}
export default App;
