import React, { Component } from 'react';

import { Switch, Route } from "react-router-dom";
import Login from './views/users/login';
// import Dashboard from './views/patients/PatientList';
import Dashboard from './views/users/dashboard';

const routes = [
  {
    path: "/",
    exact: true,
    component: Login
  },
  {
    path: "/dashboard",
    exact: true,
    component: Dashboard
  }
];

export default () => {
  return (
    <Switch>
      {
        routes.map((route, i) => (
          <Route key={i} {...route} />
        ))
      }
    </Switch>
  )
}