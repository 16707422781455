import React, { useState, useEffect } from "react";
import Iframe from 'react-iframe';

import { Row, Card } from "antd";


const Dashboard = (props) => {
    const [currentURL, setCurrentURl] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // let eu = "https://app.powerbi.com/view?r=eyJrIjoiMjRjNWI5YWItZjJmZC00YjY4LThjMTItMTg4N2E1MzIyYzE4IiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";
        // let france = "https://app.powerbi.com/view?r=eyJrIjoiY2I0YmVlOTItMDI2NS00ZWExLWFjNjktMWZhZjZmZjJiMDVjIiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9&pageName=ReportSection";
        // let germany = "https://app.powerbi.com/view?r=eyJrIjoiYmYzNGUxYzUtZGFlNi00ZGVmLThmZDItNjgxZDM3MjMwNzI0IiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";
        // let switzerland = "https://app.powerbi.com/view?r=eyJrIjoiOWVmYjZiNzItY2JhZi00MmNkLWJlYmEtMWZjZTQwZWJkZWU4IiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9&pageName=ReportSection";
        // let uk = "https://app.powerbi.com/view?r=eyJrIjoiM2RiYWUxZWMtYjU2MC00NjliLWE2YTUtNzIzYWZkYWQ0Njg4IiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";
        // let canada = "https://app.powerbi.com/view?r=eyJrIjoiYjEyNzc1MmQtNGNhMi00NTVmLWE2ODYtOTEwZDUzYzg3YzIxIiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";

        //  14-5-202
        // let eu = "https://app.powerbi.com/view?r=eyJrIjoiODUyODVhMjEtNzQ2NC00OTA4LTgyYzQtOTY0YmQ0YmY4MmNkIiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";
        // let france = "https://app.powerbi.com/view?r=eyJrIjoiNjdjNTgyMzUtMTdiNC00NTZhLWJhNDItNDU0MTE2YzU3ZThiIiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";
        // let germany = "https://app.powerbi.com/view?r=eyJrIjoiNWU1YmIzYmYtNTQ0OS00MWMyLWJiZWYtNmRjNDlhMmMzOTU3IiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";
        // let switzerland = "https://app.powerbi.com/view?r=eyJrIjoiMDAyMDkyMGItNDZlYi00ODllLTg5ZmEtYTQ5YjY4NGYwZmZmIiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";
        // let uk = "https://app.powerbi.com/view?r=eyJrIjoiOTE3NzcwZTQtNDExMS00MTA2LWJmNTMtNDEyMmQ2NjI3OGI3IiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";
        // let canada = "https://app.powerbi.com/view?r=eyJrIjoiOTVhMWViYTItNzkwMS00NzY3LWI1MDgtNTQ2YjFhNDc0OTk5IiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";
        
        //15-4-2021
        let eu = "https://app.powerbi.com/view?r=eyJrIjoiY2FhNGYxN2YtMGY0Yy00ZGVjLWJmNTktZTU2Mzk5OWNmNWFhIiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";
        let france = "https://app.powerbi.com/view?r=eyJrIjoiNjRmMTM2Y2MtYmViYi00ZDIxLTliM2QtYTk2NTFiNTQxMTY4IiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";
        let germany = "https://app.powerbi.com/view?r=eyJrIjoiNWIxMTczYWYtYjdjNi00N2Q3LWEwMTMtNjNiNDkxZTQwMDRlIiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";
        let switzerland = "https://app.powerbi.com/view?r=eyJrIjoiN2JiMzMxODYtN2MwMy00MGE4LTliOTEtODc4ZTNjY2IxOTZmIiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";
        let uk = "https://app.powerbi.com/view?r=eyJrIjoiMmZjNDM0N2YtOGUyMi00MGE5LTliMDItYjg3Yjk1NjdlNWQ2IiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";
        let canada = "https://app.powerbi.com/view?r=eyJrIjoiMTUxMWEyOTQtNTZlZS00MWJmLWEyN2UtNmMxYjI5OWE5Y2Q5IiwidCI6IjFkMDAyNTJlLTE1ZWEtNDE5MC1iZDRiLWU3MzIwZTQ4NzdiZiJ9";

        let currentCountry = localStorage.getItem('selected_country');

        if (currentCountry !== null) {
            if (currentCountry === "eu") {
                setCurrentURl(eu);
            } else if (currentCountry === "france") {
                setCurrentURl(france);
            } else if (currentCountry === "germany") {
                setCurrentURl(germany);
            } else if (currentCountry === "switzerland") {
                setCurrentURl(switzerland);
            } else if (currentCountry === "uk") {
                setCurrentURl(uk);
            } else if (currentCountry === "canada") {
                setCurrentURl(canada);
            }
        }

    }, [currentURL]);

    return (
        <div className="contentArea">
            <Iframe
                url={currentURL}
                position="absolute"
                width="100%"
                id="myId"
                className="blog-website"
                height="100%"
                styles={{ height: "25px" }}
            />

        </div>
    );
};

export default Dashboard;